<template>
  <div class="hello">
    <div align="center">
      <h1>特征监控</h1>
    </div>
    <div class="saveModelTableSetting">
      <div class="saveModelTableSettingKuai">
        <h3>添加</h3>
        <el-form ref="form" :model="saveForm" label-width="80px">
          <el-form-item label="模型名">
            <el-col :span="8">
              <el-input v-model="saveForm.modelName"></el-input>
            </el-col>
          </el-form-item>
          <el-form-item label="表名">
            <el-select v-model="saveForm.fTableName" filterable placeholder="请选择">
              <el-option
                  v-for="item in featuresTableAll"
                  :key="item"
                  :label="item"
                  :value="item">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="getFieldSetFByFTableName(saveForm.fTableName)">根据库中表名展示字段集</el-button>
          </el-form-item>
          <el-form-item label="字段集">
            <el-select v-model="selectFieldSetList"
                       filterable clearable multiple
                       placeholder="请选择"
                       @change="spliceFieldSetList">
              <el-option key="selectAll" label="全部" value="selectAll"></el-option>
              <el-option
                  v-for="item in fieldSetList"
                  :key="item"
                  :label="item"
                  :value="item">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="状态">
            <el-radio-group v-model="saveForm.status">
              <el-radio label="ACTIVE"></el-radio>
              <el-radio label="INACTIVE"></el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSaveSubmit(saveForm)">立即创建</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="searchFeaturesImg">
      <div class="searchFeaturesImgKuai">
        <h3>查询</h3>
        <div class="block">
          <el-form ref="searchForm" :model="searchData" label-width="80px">
            <el-form-item label="时间范围">
              <el-date-picker
                  v-model="searchData.timeLimit"
                  type="daterange"
                  value-format="yyyy-MM-dd"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  @change="getModelNameAll()"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="模型名称">
              <el-radio-group v-model="searchData.modelName" @change="searchTableInModel(searchData)">
                <el-radio v-for="item in modelNameList" :label="item" :key="item">
                  {{item}}
                </el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="表名称">
              <el-radio-group v-model="searchData.tableName">
                <el-radio v-for="item in tableNameList" :label="item" :key="item">
                  {{item}}
                </el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="贷款类型">
              <el-radio-group v-model="searchData.loanType">
                <el-radio label="0">首贷</el-radio>
                <el-radio label="1">复贷</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="searchMonitorValues(searchData)">搜索</el-button>
            </el-form-item>
          </el-form>
          <el-input placeholder="请输入访问服务器端口" v-model="urlShouDong">
          </el-input>
          <el-button type="primary" @click="setInit">初始化</el-button>
        </div>
      </div>
    </div>
    <div style="clear:both"></div>
    <div class="fourFeatureMaps">
      <div id="chartLineAvg" :style="{width: '80%', height: '500px'}"></div>
      <div id="chartLineMax" :style="{width: '80%', height: '500px'}"></div>
      <div id="chartLineMin" :style="{width: '80%', height: '500px'}"></div>
      <div id="chartLineDef" :style="{width: '80%', height: '500px'}"></div>
    </div>



  </div>
</template>

<script>
import * as echarts from 'echarts';
require('echarts/theme/shine');//引入主题

export default {
  data() {
    return {
      chartLine: null,
      searchData:{
        timeLimit : '',
        modelName : '',
        tableName : '',
        loanType : '0', //0 首贷 1 复贷
      },
      tableNameList : '',
      monitorData : {
        xaxisDateList : [],
        avgMap : {},
        maxMap : {},
        minMap : {},
        defMap : {}
      },
      saveForm : {
        modelName: null,
        fTableName: null,
        fFieldSet: null,
        status: null,
      },
      fieldSetList : [],
      selectFieldSetList : [],
      modelNameList : [],
      featuresTableAll : [],
      selectAll: false , // 用于标识是否全选--默认不全选
      urlceshi : 'http://47.95.112.244:8083/',
      urlBenDi : 'http://localhost:8083/',
      urlShengChan : 'http://47.88.51.165:8083/',//ppcredito 生产
      urlEfcectiveShengChan : 'http://47.88.22.232:8083/',//Efecective 生产
      urlShouDong : '' //手动输入
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.drawLineChart();
    })
  },
  methods: {
    /**
     * 初始化
     */
    setInit(){
      this.$axios({
        url: this.urlShouDong + 'monitor/getFeaturesTableAll',
        method: 'post',
        data: {}
      }).then((response) => {
        console.log(response)
        if (response.data.code === "00000"){
          this.featuresTableAll = response.data.data;
          console.log(this.saveForm)
        }
      }).catch((error) => {
        console.log(error);
      })
    },
    /**
     * 获得所有模型名称
     */
    getModelNameAll(){
      this.$axios({
        url: this.urlShouDong + 'monitor/getModelNameAll',
        method: 'post',
        data: {}
      }).then((response) => {
        console.log(response)
        if (response.data.code === "00000"){
          this.modelNameList = response.data.data;
        }
      }).catch((error) => {
        console.log(error);
      })
    }
    ,
    /**
     * 拼接字符集
     */
    spliceFieldSetList(value){
      console.log(value)
      this.changeSelect(value);
      let fFieldSet = '';
      for (const item of this.selectFieldSetList) {
        if (item == "selectAll"){
          continue;
        }
        fFieldSet += "," + item ;
      }
      fFieldSet = fFieldSet.substr(1);
      this.saveForm.fFieldSet = fFieldSet;
      console.log(this.saveForm.fFieldSet)
    }
    ,
    changeSelect(value) {
      //  selectAll 为true 的时候，就走全选分支，全选后出现的情况就是取消权限
      if (this.selectAll) {
        this.selectAll = false
        if (value.indexOf('selectAll') > -1) {
          this.selectFieldSetList = value.filter(p => p != 'selectAll')
        } else {
          this.selectFieldSetList = []
        }
      } else {
        //   是否点击了‘全选’选项
        if (value.indexOf('selectAll') > -1) {
          this.selectFieldSetList = ['selectAll', ...this.fieldSetList]
          this.selectAll = true
        } else {
          // 若是勾选选择的长度和提供的选项长度是一样的，则是 ‘全选’
          if (value.length === this.fieldSetList.length) {
            this.selectFieldSetList = ['selectAll', ...this.fieldSetList]
            this.selectAll = true
          } else {
            //   都是单选
            this.selectFieldSetList = value
          }
        }
      }
    }
    ,
    /**
     * 获得表名的所有字段
     */
    getFieldSetFByFTableName(fTableName){
      this.fieldSetList = [];
      let req = {
        fTableName : fTableName + ''
      };
      this.$axios({
        url: this.urlShouDong + 'monitor/getFieldSetFByFTableName',
        method: 'post',
        data: this.qs.stringify(req)
      }).then((response) => {
        if (response.data.code === "00000"){
          this.fieldSetList = response.data.data;
        }
      }).catch((error) => {
        console.log(error);
      })
    }
    ,
    /**
     * 添加模型配置数据库
     */
    onSaveSubmit(saveForm){
      this.$axios({
        url: this.urlShouDong + 'monitor/saveFeaturesMonitor',
        method: 'post',
        data: this.qs.stringify(saveForm)
      }).then((response) => {
        if (response.data.code === "00000"){
          this.$refs.form.resetFields();
          this.saveForm = this.$options.data().saveForm;
          this.selectFieldSetList = []
          alert("增加成功");
        }else{
          alert("参数不够");
        }
      }).catch((error) => {
        console.log(error);
      })
    }
    ,
    /**
     * 根据[模型名称]搜索关联[特征表]
     * @param searchData
     */
    searchTableInModel(searchData){
      let req = {
        modelName : searchData.modelName
      }
      this.$axios({
        url: this.urlShouDong +  'monitor/getTableInModel',
        method: 'post',
       data: this.qs.stringify(req)
      }).then((response) => {
        if (response.data.code === "00000"){
          this.tableNameList = response.data.data;
        }
      }).catch((error) => {
        console.log(error);
      })
    },
    /**
     * 根据[五个条件]搜索特征的四个值[均,大,小,缺]
     * @param searchData
     */
    searchMonitorValues(searchData){
      console.log(searchData.timeLimit[0],searchData.timeLimit[1],searchData.modelName ,searchData.tableName);
      let req = {
        startTime : searchData.timeLimit[0],
        endTime : searchData.timeLimit[1],
        modelName : searchData.modelName,
        tableName : searchData.tableName,
        loanType : searchData.loanType
      }
      this.$axios({
        url: this.urlShouDong + 'monitor/getFeaturesMonitorValue',
        method: 'post',
        data: this.qs.stringify(req)
      }).then((response) => {
        if (response.data.code === "00000"){
          this.monitorData.xaxisDateList = response.data.data.xaxisDateList;
          this.monitorData.avgMap = response.data.data.avgMap;
          this.monitorData.maxMap = response.data.data.maxMap;
          this.monitorData.minMap = response.data.data.minMap;
          this.monitorData.defMap = response.data.data.defMap;
          console.log(this.monitorData)
          this.drawLineChart(this.monitorData);
        }
      }).catch((error) => {
        console.log(error);
      })
    }
    ,
    /**
     * 绘制 四个图
     * @param monitorData
     */
    drawLineChart(monitorData){
      this.chartLineAvg = echarts.init(document.getElementById('chartLineAvg'));
      this.chartLineMax = echarts.init(document.getElementById('chartLineMax'));
      this.chartLineMin = echarts.init(document.getElementById('chartLineMin'));
      this.chartLineDef = echarts.init(document.getElementById('chartLineDef'));

      this.chartLineAvg.clear();
      this.chartLineMax.clear();
      this.chartLineMin.clear();
      this.chartLineDef.clear();

      // 使用刚指定的配置项和数据显示图表
      this.chartLineAvg.setOption(this.getOptionValue("平均值", monitorData, monitorData.avgMap));
      this.chartLineMax.setOption(this.getOptionValue("最大值", monitorData, monitorData.maxMap));
      this.chartLineMin.setOption(this.getOptionValue("最小值", monitorData, monitorData.minMap));
      this.chartLineDef.setOption(this.getOptionValue("缺省值", monitorData, monitorData.defMap));
    },
    getOptionValue(title, monitorData, monitorDataMap) {
      let option = {
        title: {
          text: title
        },
        tooltip : {
          trigger: 'axis'
        },
        legend: {
          data:[],
          type: 'scroll',
          top : 30,
          left : 100,
        },
        calculable : true,
        xAxis : [
          {
            type : 'category',
            boundaryGap : false,
            axisTick: {
              show: false
            },
            data : monitorData.xaxisDateList
          }
        ],
        yAxis : [
          {
            type : 'value',
            axisTick: {
              show: false
            }
          }
        ],
        series : []
      };

      // 赋值
      for (var key in monitorDataMap) {
        option.legend.data.push(key);
        let seriesObject = {
          name : key,
          type : 'line',
          data : monitorDataMap[key],
          itemStyle : { normal: {label : {show: true}}}
        }
        option.series.push(seriesObject);
      }
      console.log(option)
      return option;
    }
  }
}
</script>
<style>
.saveModelTableSetting {
  float: left;
  width: 48%;
  margin-left: 2%;
}
.searchFeaturesImg{
  float: right;
  width: 50%;
}
.fourFeatureMaps{
  margin-left: 10%;
}
</style>
